<template>
  <a :href="`/app/campus?id=${item.id}`" class="app-card">
    <div class="app-card__content">
      <div class="avatar-wrapper" :class="{ creator: isCreator }">
        <Avatar type="big" :avatar="item.image" />
      </div>

      <div class="app-card__info">
        <h2 class="app-card__name">{{ item.name }}</h2>
        <div class="participants-badge app-card__participants-badge">
          <SvgPeople2 iconColor="#8771DE" />
          <span>{{ item.followersCount }}</span>
        </div>
        <p class="app-card__description">{{ item.description }}</p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'CardCampus',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isCreator: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-card {
  background-color: #fff;
  padding: 20px 18px 18px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 160px;
  box-shadow: 0px 30px 40px -16px rgba(113, 109, 150, 0.11);

  @media (max-width: $sm) {
    padding: 16px;
    min-height: unset;
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__name,
  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__name {
    font-size: var(--text-lg);
    font-weight: 600;
    color: #25252b;
    -webkit-line-clamp: 2;
    order: 1;

    @media (max-width: $sm) {
      font-size: var(--text-sm);
    }
  }

  &__participants-badge {
    margin-top: 8px;
    order: 2;

    @media (max-width: $sm) {
      order: 3;
    }
  }

  &__description {
    margin-top: 8px;
    -webkit-line-clamp: 3;
    font-size: var(--text-xs);
    color: #9fa2b5;
    order: 2;

    @media (max-width: $sm) {
      font-size: 10px;
      -webkit-line-clamp: 2;
    }
  }
}

.avatar-wrapper {
  position: relative;
  margin-right: 20px;

  &.creator {
    &::before {
      content: '';
      position: absolute;
      bottom: -3px;
      right: -3px;
      background: url(../img/campus-creator-icon.png);
      background-size: cover;
      width: 12px;
      height: 12px;
      border: 2px solid var(--white);
      border-radius: 50%;
      z-index: 1;
    }
  }

  .avatar--big {
    @media (max-width: $sm) {
      width: 70px;
      height: 70px;
    }
  }
}
</style>
