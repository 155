import { ApiClient } from './ApiClient.js';
const config = {
  transformResponse: [
    function (data) {
      return JSON.parse(data).data;
    },
  ],
};
export default {
  getUserProfile() {
    return ApiClient.get('/user', config);
  },
  getForeignUserProfile(id) {
    return ApiClient.get(`/user/${id}`, config);
  },
  getUserFirebaseToken() {
    return ApiClient.get('/user/firebase/token');
  },
  getUserBrief(id) {
    return ApiClient.get(`/user/${id}/brief`, config);
  },
  getCampusesFollower(page = 1) {
    return ApiClient.get(`/user/campuses/follower?page=${page}`, config);
  },
  getCampusesModerator(page = 1) {
    return ApiClient.get(`/user/campuses/moderator?page=${page}`, config);
  },
  getUserBalance() {
    return ApiClient.get('/user/settings/balance', config);
  },
  updateUserPhoto(data) {
    return ApiClient.post('/user', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  updateUserProfile(data) {
    return ApiClient.put('/user', data, config);
  },
  getForeignCampusesModerator(id, page = 1) {
    return ApiClient.get(`/user/${id}/campuses/moderator?page=${page}`, config);
  },
  getForeignCampusesFollower(id, page = 1) {
    return ApiClient.get(`/user/${id}/campuses/follower?page=${page}`, config);
  },
  followUser(id) {
    return ApiClient.post('/user/followings', {
      users: [id],
    });
  },
  unfollowUser(id) {
    return ApiClient.delete('/user/followings', { data: { users: [id] } });
  },
  getForeignUserFollowers(id, page = 1) {
    return ApiClient.get(`/user/${id}/followers?page=${page}`, config);
  },
  getForeignUserFollowings(id, page = 1) {
    return ApiClient.get(`/user/${id}/followings?page=${page}`, config);
  },
};
