<template>
  <div class="container mb-6 mt-page">
    <!-- <p class="tag mb-3">{{ $t('community') }}</p> -->
    <h1 class="title">{{ $t('my_campuses') }}</h1>
    <p class="subtitle campuses-subtitle">{{ $t('my_campuses_subtitle') }}</p>

    <ul class="tabs mt-4">
      <li
        v-for="(tab, key) in tabs"
        :key="key"
        @click="activeTab = key"
        :class="{ active: activeTab === key }"
      >
        {{ $t(key) }}
      </li>
    </ul>

    <div class="campuses">
      <CardCampus
        class="card-campus"
        v-for="campus in tabs[activeTab].items"
        :item="campus"
        :key="campus.id"
        :is-creator="isCreator"
      />
      <Observer class="dummy" @intersect="handleIntersect"></Observer>
    </div>
  </div>
</template>

<script>
import UserService from '@/api/UserService';
import CardCampus from '../components/CardCampus.vue';
import { Vue } from 'vue-property-decorator';
import Observer from '../components/Observer.vue';

export default {
  components: {
    CardCampus,
    Observer,
  },
  data() {
    return {
      currentUser: {},
      campusesModerator: [],
      campusesFollower: [],
      tabs: {
        moderator: {
          items: [],
          pagination: {},
        },
        participant: {
          items: [],
          pagination: {},
        },
      },
      activeTab: 'moderator',
    };
  },

  mounted() {
    this.getCampusesFollower();
    this.getCampusesModerator();
  },
  computed: {
    isCreator() {
      return this.activeTab === 'moderator' ? true : false;
    },
  },

  methods: {
    async getCampusesFollower(nextPage = 1) {
      const { data } = await UserService.getCampusesFollower(nextPage);
      const { pagination, items } = data;

      const newItems = this.tabs.participant.items.concat(items);

      Vue.set(this.tabs, 'participant', {
        ...this.tabs.participant,
        items: newItems,
        pagination,
      });
    },
    async getCampusesModerator(nextPage = 1) {
      const { data } = await UserService.getCampusesModerator(nextPage);
      const { pagination, items } = data;

      const newItems = this.tabs.moderator.items.concat(items);

      Vue.set(this.tabs, 'moderator', {
        ...this.tabs.moderator,
        items: newItems,
        pagination,
      });
    },

    async handleIntersect() {
      const { lastPage, currentPage } = this.tabs[this.activeTab].pagination;

      if (lastPage > currentPage) {
        let nextPage = (this.tabs[this.activeTab].pagination.currentPage += 1);

        const METHODS = {
          participant: this.getCampusesFollower,
          moderator: this.getCampusesModerator,
        };
        METHODS[this.activeTab](nextPage);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.campuses {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  column-gap: 40px;
  row-gap: 20px;
  margin-top: 20px;

  @media (max-width: $sm) {
    margin-top: 12px;
  }
}

.card-campus {
  width: 100%;
}

.campuses-subtitle {
  max-width: 360px;
  margin-top: 28px;
}
</style>
